<template>
  <div>
    <SkinnyBanner page="landing" />
    <RevContainer class="mb-16 mt-32" data-spec="body">
      <AboutSeller class="mb-36 mt-28" />
      <div data-spec="main">
        <section data-test="product-results-refinement">
          <div class="mb-12 justify-between lg:mb-32 lg:flex">
            <span />
          </div>
          <div class="sticky -top-1 z-[1]">
            <TopFilters
              v-model:filters="filtersInput"
              v-model:price="priceInput"
              v-model:sort="sortInput"
              :facets
              :price-facet
              :search-nb-results="total"
              :sort-options
            />
          </div>

          <div class="mt-16 lg:flex lg:gap-16">
            <main class="w-full">
              <span
                class="text-static-default-low body-2 mb-12 block lg:text-caption md:mb-16"
                data-selector="total-products"
                data-test="total-products"
              >
                {{
                  i18n(translations.resultsCount, {
                    total,
                  })
                }}
              </span>
              <SwapPill v-if="displaySwapComponent" class="lg:hidden" />

              <NoResults
                v-if="products.length === 0"
                class="my-32"
                data-spec="body"
              />
              <ResultGrid v-else class="mt-8 md:mt-32" :products />
            </main>
          </div>

          <Pagination v-model="pageInput" :page-count />
        </section>
      </div>
    </RevContainer>
    <FullscreenFilters
      v-model:filters="filtersInput"
      v-model:price="priceInput"
      v-model:sort="sortInput"
      :facets
      :price-facet
      :search-nb-results="total"
      :sort-options
    />
  </div>
</template>

<script lang="ts" setup>
import {
  createError,
  useHead,
  useRequestURL,
  useRoute,
  useRouteParams,
  useRuntimeConfig,
} from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'

import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import SwapPill from '~/scopes/buyback/swap/components/SwapPill/SwapPill.vue'
import {
  getSortOptions,
  useAlgoliaApiKey,
  useAlgoliaConfiguration,
} from '~/scopes/search/helpers/helpers'
import { getMetas } from '~/utils/seo/meta'

import { useProductsSearch } from '../../search/composables/useProductsSearch'
import AboutSeller from '../components/AboutSeller.vue'
import FullscreenFilters from '../components/FullscreenFilters.vue'
import NoResults from '../components/NoResults.vue'
import Pagination from '../components/Pagination.vue'
import ResultGrid from '../components/ResultGrid.vue'
import TopFilters from '../components/TopFilters/TopFilters.vue'
import { useFiltersFromHash } from '../composables/useHashFilters'
import { useSellerProductListPage } from '../composables/useSellerProductListPage'

import translations from './SellerProductListPage.translations'

const { uuid: id } = useRouteParams()
const { countryCode } = useMarketplace().market

const { data: searchConfig } = await useAlgoliaConfiguration(
  'merchant-landing',
  id,
)
if (searchConfig.value === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to retrieve search configuration',
    fatal: true,
  })
}
const { data: apiKeyConfig } = await useAlgoliaApiKey(
  searchConfig.value.indexType,
)
if (!apiKeyConfig.value) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to get algolia API key',
    fatal: true,
  })
}

const sortOptions = getSortOptions(searchConfig.value.indexes)

const {
  public: { ALGOLIA_ID, FF_SWAP_PLP_BLOCKS },
} = useRuntimeConfig()

const {
  total,
  products,
  facets,
  pageCount,
  priceFacet,
  filtersInput,
  sortInput,
  pageInput,
  priceInput,
} = await useProductsSearch(
  ALGOLIA_ID,
  searchConfig.value,
  apiKeyConfig.value.apiKey,
)

useFiltersFromHash(filtersInput, priceInput, pageInput)

const i18n = useI18n()
const route = useRoute()
const { company } = useSellerProductListPage(id)
const metaImageUrl = '/img/plp/ogImage.png'
const { protocol, hostname } = useRequestURL()
const {
  public: { COMPANY_NAME },
} = useRuntimeConfig()
const pageNumber = Array.isArray(route.query.p)
  ? route.query.p[0]
  : route.query.p
useHead({
  title: pageNumber
    ? i18n(translations.landingSellerMetaTitleWithPage, {
        sellerName: company.value,
        pageNumber: parseInt(pageNumber, 10) + 1,
      })
    : i18n(translations.landingSellerMetaTitleNew, {
        sellerName: company.value,
        merchantName: company.value,
      }),
  titleTemplate: `%s | ${COMPANY_NAME}`,
  meta: getMetas({
    content: i18n(translations.landingSellerMetaDescriptionNew, {
      sellerName: company.value,
      merchantName: company.value,
    }),
    image: metaImageUrl,
    'og:type': 'website',
  }),
  link: [
    {
      href: `${protocol}//${hostname}${route.path}`,
      rel: 'canonical',
    },
  ],
})

const displaySwapComponent = computed(() =>
  (FF_SWAP_PLP_BLOCKS ?? '').includes(countryCode),
)
</script>
